import React, { PureComponent } from 'react'
import { withTranslate } from 'react-redux-multilingual'
import { Tooltip, Button } from '@material-ui/core';
import { TextStyleIcon } from '../ui/Icons'

class TextStyleButton extends PureComponent {

    componentDidMount() {
        this.props.addSteps({
            id: 'TextStyle',
            textTranslate: 'TOUR.EDITOR.TextStyle',
            selector: '#TextStyleButton',
            position: 'bottom',
            type: 'hover',
            isFixed: true,
            allowClicksThruHole: true,
            style: {
                hole: {
                    borderRadius: '100%'
                }
            }
        });
    }

    render() {
        const { translate, onTextStyle } = this.props;

        return (
            <Tooltip title={`${translate('EDITOR.controlsStyleText')}`} placement="bottom">
                <Button
                    id="TextStyleButton"
                    className="TextStyleButton"
                    color="default"
                    onClick={onTextStyle}>
                    <TextStyleIcon />  {translate('EDITOR.controlsTextStyle')}
                </Button>
            </Tooltip>
        )
    }
}

export default withTranslate(TextStyleButton)
