import { TEXT_STYLES } from '../settings'; // Contains { BOLD, ITALIC, UNDERLINE }
import { numberOfSpaces, getWordsBefore, getWordsAfter } from './wordsUtils';

/**
 * Get segment styled words
 *
 * @param  {Segment} segment
 * @param  {Array<TextStyle>} styles
 * @return {Array<TextStyle>}
 */
export function getSegmentStyledWords(segment, styles) {
    const wordsArray = [...segment.words];
    const stylesArray = [...styles];
    let output = [];

    for (let i = 0; i < wordsArray.length; i++) {
        let { start, end } = wordsArray[i];
        let appliedStyles = [];

        for (let j = 0; j < stylesArray.length; j++) {
            if (start >= stylesArray[j].start && end <= stylesArray[j].end) {
                appliedStyles.push(stylesArray[j].type); // Collect all styles applied to the word
            }
        }

        if (appliedStyles.length > 0) {
            output.push({ ...wordsArray[i], styles: appliedStyles });
        }
    }

    return output;
}

/**
 * Check which styles are applied in a given segment range.
 *
 * @param  {number} startPosition
 * @param  {number} endPosition
 * @param  {Array<TextStyle>} styles
 * @return {Array<TextStyle>}
 */
export function checkStyledWordsInSegment(startPosition, endPosition, styles) {
    return styles
        .toArray()
        .filter(s => s.startPosition < endPosition && s.endPosition > startPosition)
        .map(s => ({
            startPosition: Math.max(s.startPosition, startPosition),
            endPosition: Math.min(s.endPosition, endPosition),
            type: s.type,
        }));
}

/**
 * Get styled words for waveform visualization
 *
 * @param  {Array<TextStyle>} styles
 * @param {number} startTimeOffset
 * @param {number} duration
 * @return {Array<StyledWaveform>}
 */
export function getWaveformStyles(styles, startTimeOffset, duration) {
    let output = [];

    for (let i = 0; i < styles.length; i++) {
        let style = styles[i];
        let styleStartPercent = ((style.start - startTimeOffset) * 100) / duration;
        let styleWidth = ((style.end - style.start) * 100) / duration;

        output.push({
            left: `${styleStartPercent}%`,
            width: `${styleWidth}%`,
            styles: style.types, // Store multiple styles
        });
    }
    return output;
}

/**
 * Get styles from content state (draft-js representation of the UI)
 *
 * @param {Object} contentState From `draft-js`
 */
export function getStylesFromContent(contentState) {
    let styleRanges = [];

    let continuesBlockPositions = 0;
    let extraSpacesCount = 0;

    contentState.getBlockMap().forEach((block) => {
        const blockKey = block.getKey();
        const textWithNoExtraSpace = block.getText().split(' ').filter(t => t !== '').join(' ');

        // Extract styles dynamically
        const allStyles = new Set();
        block.getCharacterList().forEach((char) => {
            char.getStyle().forEach(style => allStyles.add(style));
        });

        allStyles.forEach((styleType) => {
            block.findStyleRanges(
                (character) => character.hasStyle(styleType),
                (startPosition, endPosition) => {
                    const words = block.data.get('words');
                    const text = block.getText();

                    const beforeStartPositionText = (text[startPosition - 1] !== ' ') 
                        ? text.substring(0, startPosition) 
                        : text.substring(0, startPosition - 1);
                    const beforeStartPositionTextWithNoExtraSpace = beforeStartPositionText.split(' ').filter(t => t !== '').join(' ');
                    let startPosition2 = startPosition - (beforeStartPositionText.length - beforeStartPositionTextWithNoExtraSpace.length);

                    const beforeEndPositionText = text.substring(0, endPosition);
                    const beforeEndPositionTextWithNoExtraSpace = beforeEndPositionText.split(' ').filter(t => t !== '').join(' ');
                    let endPosition2 = endPosition - (beforeEndPositionText.length - beforeEndPositionTextWithNoExtraSpace.length);

                    const spaces = numberOfSpaces(text);
                    const firstWord = getWordsBefore(words, startPosition - spaces + 1).last() || block.data.get('words').first();
                    const lastWord = getWordsAfter(words, endPosition - spaces - 1).first() || block.data.get('words').last();

                    if (firstWord && lastWord) {
                        const start = firstWord.get('start');
                        const end = lastWord.get('end');

                        styleRanges.push({
                            start,
                            end,
                            startPosition: startPosition2 + continuesBlockPositions - extraSpacesCount,
                            endPosition: endPosition2 + continuesBlockPositions - extraSpacesCount,
                            type: styleType,  // Store the style type (BOLD, ITALIC, etc.)
                            _atEndOfBlock: block.getLength() === endPosition,
                            _atStartOfBlock: startPosition === 0,
                            _blockKey: blockKey,
                        });
                    }
                }
            );
        });

        extraSpacesCount += block.getText().length - textWithNoExtraSpace.length;
        continuesBlockPositions += block.getText().length + 1;
    });

    return mergeContiguousStyleRanges(contentState, styleRanges);
}

/**
 * Merge contiguous style ranges while preserving multiple styles
 *
 * @param  {Array<TextStyle>} styles
 * @return {Array<TextStyle>}
 */
export function mergeContiguousStyleRanges(contentState, styles) {
    let mergedStyles = [];
    let stylesIdxs = {};

    for (let i = 0; i < styles.length; i++) {
        const style = styles[i];
        const startAt = _getStartAt(contentState, styles, style, i);
        const endAt = _getEndAt(contentState, styles, style, i);
        stylesIdxs[startAt] = endAt;
    }

    for (let startIdx in stylesIdxs) {
        let endIdx = stylesIdxs[startIdx];
        let { start, startPosition, type } = styles[startIdx];
        let { end, endPosition } = styles[endIdx];

        mergedStyles.push({
            start,
            end,
            startPosition,
            endPosition,
            type,  // Keep track of the style type
        });
    }
    return mergedStyles;
}

/**
 * Find the first styled entity in the range
 *
 * @param  {Array<TextStyle>} list
 * @param  {TextStyle} currentStyle
 * @param  {number} idx
 * @return {number}
 */
function _getStartAt(contentState, list, currentStyle, idx) {
    const prevStyle = list[idx - 1] || {};

    if (currentStyle._atStartOfBlock) {
        if (!prevStyle._atEndOfBlock) {
            return idx;
        }
        const prevBlock = contentState.getBlockBefore(currentStyle._blockKey);
        if (!prevBlock || prevBlock.getKey() !== prevStyle._blockKey) {
            return idx;
        }
    } else {
        if (currentStyle.startPosition !== prevStyle.endPosition) {
            return idx;
        }
    }
    return _getStartAt(contentState, list, prevStyle, idx - 1);
}

/**
 * Find the last styled entity in the range
 *
 * @param  {Array<TextStyle>} list
 * @param  {TextStyle} currentStyle
 * @param  {number} idx
 * @return {number}
 */
function _getEndAt(contentState, list, currentStyle, idx) {
    const nextStyle = list[idx + 1] || {};

    if (currentStyle._atEndOfBlock) {
        if (!nextStyle._atStartOfBlock) {
            return idx;
        }
        const nextBlock = contentState.getBlockAfter(currentStyle._blockKey);
        if (!nextBlock || nextBlock.getKey() !== nextStyle._blockKey) {
            return idx;
        }
    } else {
        if (currentStyle.endPosition !== nextStyle.startPosition) {
            return idx;
        }
    }
    return _getEndAt(contentState, list, nextStyle, idx + 1);
}
