import { EditorState, Modifier } from '@amberscript/amberscript-draftjs/lib/Draft';

const styleText = (editorState, style, applyAll = false) => {    
    let selection = editorState.getSelection();
    const content = editorState.getCurrentContent();

    // If "Apply All" is selected, modify the selection to cover all text
    if (applyAll) {
        const firstBlock = content.getFirstBlock();
        const lastBlock = content.getLastBlock();

        selection = selection.merge({
            anchorKey: firstBlock.getKey(),
            anchorOffset: 0,
            focusKey: lastBlock.getKey(),
            focusOffset: lastBlock.getText().length, // Select all text
            isBackward: false
        });

        editorState = EditorState.forceSelection(editorState, selection);
    }

    if (selection.isCollapsed()) return editorState; // Ignore empty selections

    let startKey = selection.getStartKey();
    let endKey = selection.getEndKey();
    const startBlock = content.getBlockForKey(startKey);
    const endBlock = content.getBlockForKey(endKey);

    const textStartBlock = startBlock.getText();
    const textEndBlock = endBlock.getText();

    let start = selection.getStartOffset();
    let end = selection.getEndOffset() - 1;

    const isSelectionOnlyWhiteSpace = checkSelectionForWhiteSpace(
        startKey === endKey, textStartBlock, textEndBlock, start, end
    );

    if (isSelectionOnlyWhiteSpace) {
        return null;
    }
    
    while (textStartBlock[start] === ' ') start += 1;
    while (textEndBlock[end] === ' ' && end !== 0) end -= 1;

    selection = selection.merge({
        anchorKey: startKey,
        anchorOffset: start,
        focusKey: endKey,
        focusOffset: end + 1,
        isBackward: false
    });

    editorState = EditorState.forceSelection(editorState, selection);

    // **🔹 Preserve Existing Styles & Merge New One**
    const currentInlineStyles = editorState.getCurrentInlineStyle();
    let newStyleSet = currentInlineStyles.add(style); // Merge styles instead of overwriting

    let newContentState = Modifier.applyInlineStyle(content, selection, style);
    let newEditorState = EditorState.push(editorState, newContentState, 'change-inline-style');
    
    return newEditorState;
};

/**
 * Check if the selected text contains only whitespace.
 */
const checkSelectionForWhiteSpace = (isSelectionInOneSegment, textStartBlock, textEndBlock, start, end) => {
    if (isSelectionInOneSegment) {
        return textStartBlock.slice(start, end).trim() === "";
    }
    return (textStartBlock.slice(start) + textEndBlock.slice(0, end + 1)).trim() === "";
};

export default styleText;
