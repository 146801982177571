import React, { Component } from "react";
import {
  Box,
  Button,
  IconButton,
  Select,
  MenuItem,
  Input,
  Checkbox,
  FormControlLabel,
  Slider, // Import Slider for opacity
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
import VerticalAlignCenterIcon from "@material-ui/icons/VerticalAlignCenter";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import { BlockPicker } from "react-color";

const styles = (theme) => ({
  button: {
    fontSize: 18,
  },
  iconButton: {
    margin: theme.spacing(1),
    color: "#444",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "10px",
    "&.active": {
      backgroundColor: "#ddd", // Active state (highlighted)
    },
  },
  pickerContainer: {
    position: "absolute",
    zIndex: 1,
    backgroundColor: "#fff",
    padding: "15px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    borderRadius: "12px",
    border: "1px solid #ddd",
  },
  colorBox: {
    width: "30px",
    height: "30px",
    display: "inline-block",
    border: "1px solid #000", // Black border
    margin: "5px",
    cursor: "pointer",
  },
  container: {
    position: "fixed",
    top: "200px",
    left: "0px",
    zIndex: 1000,
    width: "600px",
    height: "350px",
    backgroundColor: "#fff",
    padding: "15px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    borderRadius: "12px",
    border: "1px solid #ddd",
  },
  container1: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "auto auto auto auto",
    gap: "15px",
  },
  section: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  section2: {
    display: "flex",
    alignItems: "center",
    gap: "1px",
  },
  addColorInput: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    cursor: "pointer",
    border: "none",
    background: "none",
  },
  opacitySlider: {
    display: "block !important",
    height: "auto !important",
    width: "100px",
    padding: "10px",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "#f5f5f5",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "18px",
    border: "1px solid #ddd",
    "&:hover": {
      backgroundColor: "#ddd",
    },
  },
});

class TextStyle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fontWeight: "normal", 
      fontStyle: "normal", 
      textDecoration: "none", 
      textAlign: "center",
      verticalAlign: "bottom", 
      fontSize: "16px", 
      fontFamily: "Arial", 
      textColor: "#666666", 
      backgroundColor: "#ffffff", 
      backgroundOpacity: 1, 
      applyAll: false, // State for "Apply to All"
      customColors: [
        "#000000", // Black
        "#FFD700", // gold
        "#FF0000", // Red
        "#00FF00", // Green
        "#0000FF", // Blue
        "#D9E3F0", // Light Blue
        "#F47373", // Light Red
        "#697689", // Gray
        "#37D67A", // Light Green
        "#2CCCE4", // Light Blue
        "#555555", // Dark Gray
        "#dce775", // Light Yellow
        "#ff8a65", // Light Orange
        "#ba68c8", // Light Purple
        "#FFC107", // Amber
      ],
      displayTextColorPicker: false,
      displayBackgroundColorPicker: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.selectedStyles !== this.props.selectedStyles) {
      // Only update state if the individual styles have changed
      this.setState((prevState) => {
        let newState = {};
        if (prevProps.selectedStyles.fontWeight !== this.props.selectedStyles.fontWeight) {
          newState.fontWeight = this.props.selectedStyles.fontWeight;
        }
        if (prevProps.selectedStyles.fontStyle !== this.props.selectedStyles.fontStyle) {
          newState.fontStyle = this.props.selectedStyles.fontStyle;
        }
        if (prevProps.selectedStyles.textDecoration !== this.props.selectedStyles.textDecoration) {
          newState.textDecoration = this.props.selectedStyles.textDecoration;
        }
        if (prevProps.selectedStyles.textAlign !== this.props.selectedStyles.textAlign) {
          newState.textAlign = this.props.selectedStyles.textAlign;
        }
        if (prevProps.selectedStyles.verticalAlign !== this.props.selectedStyles.verticalAlign) {
          newState.verticalAlign = this.props.selectedStyles.verticalAlign;
        }
        if (prevProps.selectedStyles.fontSize !== this.props.selectedStyles.fontSize) {
          newState.fontSize = this.props.selectedStyles.fontSize;
        }
        if (prevProps.selectedStyles.fontFamily !== this.props.selectedStyles.fontFamily) {
          newState.fontFamily = this.props.selectedStyles.fontFamily;
        }
        if (prevProps.selectedStyles.textColor !== this.props.selectedStyles.textColor) {
          newState.textColor = this.props.selectedStyles.textColor;
        }
        if (prevProps.selectedStyles.backgroundColor !== this.props.selectedStyles.backgroundColor) {
          newState.backgroundColor = this.props.selectedStyles.backgroundColor;
        }
        if (prevProps.selectedStyles.backgroundOpacity !== this.props.selectedStyles.backgroundOpacity) {
          newState.backgroundOpacity = this.props.selectedStyles.backgroundOpacity;
        }
  
        return newState;
      });
    }
  }
  toggleStyle = (style) => {
    this.setState((prevState) => {
      let newState = {};
      if (style === "bold") {
        newState.fontWeight =
          prevState.fontWeight === "bold" ? "normal" : "bold";
      } else if (style === "italic") {
        newState.fontStyle =
          prevState.fontStyle === "italic" ? "normal" : "italic";
      } else if (style === "underline") {
        newState.textDecoration =
          prevState.textDecoration === "underline" ? "none" : "underline";
      }
      return newState;
    });
  };

  // Apply styles to the selected text or all
  applyStyle = () => {
    const { applyAll, ...styles } = this.state;
    this.props.onApplyStyle({ ...styles, applyAll });
    this.props.onClose(); // Close the dialog after applying
  };

  handleOpacityChange = (e, newValue) => {
    this.setState({ backgroundOpacity: newValue });
  };
  handleTextColorChange = (color) => {
    this.setState({ textColor: color.hex });
    this.setState({ displayTextColorPicker: false });
  };
  handleTextColorPickerToggle = (event) => {
    if (event) {
      this.setState({ pickerPosition: { x: event.clientX, y: event.clientY } });
    }
    this.setState({
      displayTextColorPicker: !this.state.displayTextColorPicker,
    });
  };

  handleBackgroundColorChange = (color) => {
    this.setState({ backgroundColor: color.hex });
    this.setState({ displayBackgroundColorPicker: false });
  };

  handleBackgroundColorPickerToggle = (event) => {
    if (event) {
      this.setState({ pickerPosition: { x: event.clientX, y: event.clientY } });
    }
    this.setState({
      displayBackgroundColorPicker: !this.state.displayBackgroundColorPicker,
    });
  };

  render() {
    const { classes, open } = this.props;
    const {
      fontWeight,
      fontStyle,
      textDecoration,
      textAlign,
      verticalAlign,
      fontSize,
      fontFamily,
      textColor,
      backgroundColor,
      backgroundOpacity,
      applyAll,
      customColors,
      displayTextColorPicker,
      displayBackgroundColorPicker,
    } = this.state;
    if (!open) return null;

    return (
      <Box ref={this.props.boxRef} className={classes.container}>
        {/* Close Button in Top Right */}
        <IconButton
          className={classes.closeButton}
          onClick={this.props.onClose}
        >
          ✖
        </IconButton>
        <Box className={classes.container1}>
          {/* Font Family */}
          <Box className={classes.section}>
            <Select
              key={this.state.fontFamily}
              fullWidth
              value={fontFamily}
              onChange={(e) => {
                this.setState({ fontFamily: e.target.value });
              }}
            >
              <MenuItem value="Arial">Arial</MenuItem>
              <MenuItem value="Courier New">Courier New</MenuItem>
              <MenuItem value="Georgia">Georgia</MenuItem>
              <MenuItem value="Helvetica">Helvetica</MenuItem>
              <MenuItem value="Open Sans">Open Sans</MenuItem>
              <MenuItem value="Roboto">Roboto</MenuItem>
              <MenuItem value="Tahoma">Tahoma</MenuItem>
              <MenuItem value="Times New Roman">Times New Roman</MenuItem>
              <MenuItem value="Verdana">Verdana</MenuItem>
            </Select>
          </Box>

          {/* Font Size */}
          <Box className={classes.section}>
            <Input
              style={{ width: "100px" }}
              type="number"
              value={fontSize.replace("px", "")}
              onChange={(e) =>
                this.setState({ fontSize: `${e.target.value}px` })
              }
            />
          </Box>

          <Box className={classes.section}>
            <div
              className={classes.colorBox}
              style={{ background: textColor }}
              onClick={this.handleTextColorPickerToggle}
            />
            {displayTextColorPicker && (
              <div
                className={classes.pickerContainer}
                style={{
                  left: `${this.state.pickerPosition.x} -20 px`,
                  top: "50px",
                }}
              >
                <BlockPicker
                  color={textColor}
                  colors={customColors}
                  triangle={"hide"}
                  onChangeComplete={this.handleTextColorChange}
                  onChange={this.handleTextColorChange}
                />
              </div>
            )}
          </Box>

          <Box className={classes.section}>
            <Box className={classes.section2}>
              <IconButton
                className={`${classes.iconButton} ${
                  fontWeight === "bold" ? "active" : ""
                }`}
                onClick={() => this.toggleStyle("bold")}
              >
                <FormatBoldIcon />
              </IconButton>
              <IconButton
                className={`${classes.iconButton} ${
                  fontStyle === "italic" ? "active" : ""
                }`}
                onClick={() => this.toggleStyle("italic")}
              >
                <FormatItalicIcon />
              </IconButton>
              <IconButton
                className={`${classes.iconButton} ${
                  textDecoration === "underline" ? "active" : ""
                }`}
                onClick={() => this.toggleStyle("underline")}
              >
                <FormatUnderlinedIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Text Alignment */}
          <Box className={classes.section}>
            <Box className={classes.section2}>
              <IconButton
                className={`${classes.iconButton} ${
                  textAlign === "left" ? "active" : ""
                }`}
                onClick={() => this.setState({ textAlign: "left" })}
              >
                <FormatAlignLeftIcon />
              </IconButton>
              <IconButton
                className={`${classes.iconButton} ${
                  textAlign === "center" ? "active" : ""
                }`}
                onClick={() => this.setState({ textAlign: "center" })}
              >
                <FormatAlignCenterIcon />
              </IconButton>
              <IconButton
                className={`${classes.iconButton} ${
                  textAlign === "right" ? "active" : ""
                }`}
                onClick={() => this.setState({ textAlign: "right" })}
              >
                <FormatAlignRightIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Vertical Alignment */}
          <Box className={classes.section}>
            <Box className={classes.section2}>
              <IconButton
                className={`${classes.iconButton} ${
                  verticalAlign === "top" ? "active" : ""
                }`}
                onClick={() => this.setState({ verticalAlign: "top" })}
              >
                <VerticalAlignTopIcon />
              </IconButton>
              <IconButton
                className={`${classes.iconButton} ${
                  verticalAlign === "middle" ? "active" : ""
                }`}
                onClick={() => this.setState({ verticalAlign: "middle" })}
              >
                <VerticalAlignCenterIcon />
              </IconButton>
              <IconButton
                className={`${classes.iconButton} ${
                  verticalAlign === "bottom" ? "active" : ""
                }`}
                onClick={() => this.setState({ verticalAlign: "bottom" })}
              >
                <VerticalAlignBottomIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box className={classes.section}>
          <Box>
            <p>Background Color:</p>

            <div
              className={classes.colorBox}
              style={{ background: backgroundColor }}
              onClick={this.handleBackgroundColorPickerToggle}
            />
            {displayBackgroundColorPicker && (
              <div
                className={classes.pickerContainer}
                style={{
                  left: `${this.state.pickerPosition.x} -20 px`,
                  top: "50px",
                }}
              >
                <BlockPicker
                  color={backgroundColor}
                  colors={customColors}
                  triangle={"hide"}
                  onChangeComplete={this.handleBackgroundColorChange}
                  onChange={this.handleBackgroundColorChange}
                />
              </div>
            )}
          </Box>

          {/* Opacity Slider */}
          <Box className={classes.opacitySlider}>
            <Slider
              value={backgroundOpacity}
              onChange={this.handleOpacityChange}
              min={0}
              max={1}
              step={0.01}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => value.toFixed(2)}
            />
          </Box>
        </Box>

        <Box p={1} display="flex" width="100%" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                checked={applyAll}
                onChange={(e) => this.setState({ applyAll: e.target.checked })}
                color="primary"
              />
            }
            label="Apply to All Text"
          />
        </Box>
        <Box p={1} display="flex" width="100%" alignItems="center">
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={this.applyStyle}
            fullWidth
          >
            Apply
          </Button>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(TextStyle);
