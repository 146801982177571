import Immutable from "immutable";

const TextStyle = new Immutable.Record({
  start: 0, // @param {float} Start time offset in the whole transcript
  end: 0, // @param {float} End time offset in the whole transcript
  startPosition: 0, // @param {int} Start position within the segment
  endPosition: 0, // @param {int} End position within the segment
  textAlignment: "left",
  textColor: "#000000",
  textSize: "16px",
  background: Immutable.Map({
    color: "#FFFFFF",
    opacity: 1,
  }),
  position: "top",
  bold: false,
  italic: false,
  underline: false,
});

export default TextStyle;
