import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

import AmberVerticalTabs from "../tabs/AmberVerticalTabs";
import GlossaryPanel from "./glossary/glossaryPanel";
import getGlossaryById from "../../sagas/glossary";
import { ReactComponent as CheckStar } from "../../assets/images/ui/check_star.svg";
import { ReactComponent as Book } from "../../assets/images/ui/book.svg";
import PreQCPanel from "./pre-qc/PreQCPanel";

export default function RightPanel(props) {
  const {
    guideLines,
    translate,
    glossaryId,
    isPreQCAvailable,
    loadingQC,
    setLoadingQC,
    QCHasBeenRun,
    preQCIssues,
    runPreQC,
    language,
    openTabValue,
    resetOpenTabValue,
    jobId,
    userName,
    roles,
  } = props;

  const [glossary, setGlossary] = useState({
    id: "",
    name: "",
    names: [],
    items: [],
  });

  // Get the glossary linked to this job
  const getGlossary = (glossaryId) => {
    if (!glossaryId || glossaryId === "") {
      return;
    }

    getGlossaryById(glossaryId)
      .then((response) => {
        if (response && response.data) {
          const { id, name, names, items } = response.data;
          setGlossary({
            id,
            name,
            names,
            items,
          });
        }
      })
      // Set the id to the glossary object
      .catch(() => {
        setGlossary({
          id: glossaryId,
        });
      });
  };

  useEffect(() => {
    getGlossary(glossaryId);
  }, []);

  const preQCTab = [
    {
      key: "tab-heading-pre-qc",
      label: (
        <Box
          display="flex"
          padding={1.5}
          height="30px"
          justifyContent="center"
          alignItems="center"
          data-testid="pre-qc-box-tab-heading"
        >
          <CheckStar />
        </Box>
      ),
    },
  ];

  const tabHeadings = [
    {
      key: "tab-heading-glossary",
      label: (
        <Box
          display="flex"
          padding={1.5}
          height="30px"
          data-testid="glossary-box-tab-heading"
        >
          <Book />
        </Box>
      ),
    },
    // Adding the Pre QC tab if it's available
    ...(isPreQCAvailable ? preQCTab : []),
  ];

  const tabPanels = [
    {
      key: "tab-panel-glossary",
      component: <GlossaryPanel glossary={glossary} translate={translate} />,
    },
    {
      key: "tab-panel-pre-qc",
      component: (
        <PreQCPanel
          translate={translate}
          loadingQC={loadingQC}
          setLoadingQC={setLoadingQC}
          QCHasBeenRun={QCHasBeenRun}
          preQCIssues={preQCIssues}
          runPreQC={runPreQC}
          language={language}
          jobId={jobId}
          userName={userName}
          roles={roles}
        />
      ),
    },
  ];

  return (
    <Box
      data-testid="right-panel-tabs"
      display={{ xs: "none", sm: "none", md: "flex" }}
      position="fixed"
      top={
        guideLines
          ? { xs: 270, sm: 275, md: 285, lg: 290, xl: 310 }
          : 155
      }
      right={0}
      zIndex={9}
      height="100vh"
      bgcolor="#F5F5F5"
      boxShadow={{ sm: 200, md: 10, lg: 2, xl: 2 }}
    >
      <AmberVerticalTabs
        tabHeadings={tabHeadings}
        tabPanels={tabPanels}
        openTabValue={openTabValue}
        resetOpenTabValue={resetOpenTabValue}
      />
    </Box>
  );
}

RightPanel.propTypes = {
  guideLines: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  glossaryId: PropTypes.string,
  isPreQCAvailable: PropTypes.bool.isRequired,
  loadingQC: PropTypes.bool.isRequired,
  setLoadingQC: PropTypes.func.isRequired,
  QCHasBeenRun: PropTypes.bool.isRequired,
  preQCIssues: PropTypes.arrayOf(
    PropTypes.shape({
      end_time: PropTypes.number.isRequired,
      end_word_index: PropTypes.number.isRequired,
      issue_severity: PropTypes.string.isRequired,
      issue_type: PropTypes.string.isRequired,
      issue_type_detail: PropTypes.string,
      original_text: PropTypes.string.isRequired,
      segment_index: PropTypes.number.isRequired,
      start_time: PropTypes.number.isRequired,
      start_word_index: PropTypes.number.isRequired,
      suggestion: PropTypes.string.isRequired,
    })
  ),
  runPreQC: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  openTabValue: PropTypes.number,
  jobId: PropTypes.string,
  userName: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

RightPanel.defaultProps = {
  glossaryId: "",
  preQCIssues: [],
  openTabValue: null,
  resetOpenTabValue: () => {},
  jobId: null,
};
