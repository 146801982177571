import { convertFromRaw } from "@amberscript/amberscript-draftjs/lib/Draft";
import { HIGHLIGHT } from "../settings";
import { isTimeInBlock, isPositionInBlock } from "./wordsUtils";

const transcriptToContentState = (transcript) => {
  const rawContent = {
    blocks: [],
    entityMap: {},
  };

  // Convert transcript segments to content blocks
  rawContent.blocks = transcript.segments
    .map((segment) => {
      const words = segment.get("words");
      return {
        text: words
          .reduce((result, word) => {
            const text = word.text;
            if (text.length > 0 && text !== " ") {
              result.push(text);
            }
            return result;
          }, [])
          .join(" "),
        inlineStyleRanges: [],
        data: {
          id: segment.get("id"),
          words: words,
          speaker: segment.get("speaker"),
          newLine: segment.get("newLine"),
        },
      };
    })
    .toArray();

  // Apply Highlights
  transcript.highlights.forEach((highlight) => {
    const startBlockIndex = rawContent.blocks.findIndex((block) =>
      isPositionInBlock(highlight.startPosition, block)
    );
    const endBlockIndex = rawContent.blocks.findIndex((block) =>
      isPositionInBlock(highlight.endPosition, block)
    );

    if (startBlockIndex < 0 || endBlockIndex < 0) return;

    applyInlineStyle(
      rawContent,
      startBlockIndex,
      endBlockIndex,
      highlight.startPosition,
      highlight.endPosition,
      HIGHLIGHT
    );
  });

  // Apply Text Styles (bold, italic, font-size, etc.)
  transcript.styles.forEach((style) => {
    const startBlockIndex = rawContent.blocks.findIndex((block) =>
      isPositionInBlock(style.startPosition, block)
    );
    const endBlockIndex = rawContent.blocks.findIndex((block) =>
      isPositionInBlock(style.endPosition, block)
    );

    if (startBlockIndex < 0 || endBlockIndex < 0) return;

    applyInlineStyle(
      rawContent,
      startBlockIndex,
      endBlockIndex,
      style.startPosition,
      style.endPosition,
      style.type
    );
  });

  return convertFromRaw(rawContent);
};
const applyInlineStyle = (
  transcript,
  blockIndex,
  offset,
  length,
  newStyle,
  changeType
) => {
  const newTranscript = { ...transcript };

  newTranscript.styles = newTranscript.styles.map((existingStyle) => {
    let { startPosition, endPosition } = existingStyle;

    // If the change is inside this style, adjust start and end positions
    if (offset >= startPosition && offset <= endPosition) {
      if (changeType === "insert") {
        endPosition += length; // Move end forward when inserting text
      } else if (changeType === "delete") {
        endPosition = Math.max(startPosition, endPosition - length); // Move end backward when deleting
      }
    }
    // If the change is before this style, shift the whole style forward/backward
    else if (offset < startPosition) {
      if (changeType === "insert") {
        startPosition += length;
        endPosition += length;
      } else if (changeType === "delete") {
        startPosition = Math.max(0, startPosition - length);
        endPosition = Math.max(0, endPosition - length);
      }
    }

    return { ...existingStyle, startPosition, endPosition };
  });

  // Apply the new style by merging with existing ones
  const appliedStyles = [];
  let merged = false;

  newTranscript.styles.forEach((style) => {
    // If the new style fully overlaps with an existing one, merge them
    if (style.startPosition <= offset && style.endPosition >= offset + length) {
      appliedStyles.push({
        ...style,
        ...newStyle, // Merge new style
      });
      merged = true;
    } else {
      appliedStyles.push(style);
    }
  });

  // If no existing style matched, add a new one
  if (!merged) {
    appliedStyles.push({
      startPosition: offset,
      endPosition: offset + length,
      ...newStyle,
    });
  }

  newTranscript.styles = appliedStyles;

  // Same logic applies for highlights (preserving background color)
  newTranscript.highlights = newTranscript.highlights.map((highlight) => {
    let { startPosition, endPosition } = highlight;

    if (offset >= startPosition && offset <= endPosition) {
      if (changeType === "insert") {
        endPosition += length;
      } else if (changeType === "delete") {
        endPosition = Math.max(startPosition, endPosition - length);
      }
    } else if (offset < startPosition) {
      if (changeType === "insert") {
        startPosition += length;
        endPosition += length;
      } else if (changeType === "delete") {
        startPosition = Math.max(0, startPosition - length);
        endPosition = Math.max(0, endPosition - length);
      }
    }

    return { ...highlight, startPosition, endPosition };
  });

  return newTranscript;
};

export default transcriptToContentState;
