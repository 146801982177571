const rgbToHex = (rgb) => {
  const match = rgb.match(/\d+/g); // Extract numbers
  if (!match) return "#000000"; // Default to black if invalid
  return `#${match
    .slice(0, 3)
    .map((num) => Number(num).toString(16).padStart(2, "0"))
    .join("")}`;
};

const getOpacityFromRGBA = (rgba) => {
  const match = rgba.match(/\d+(\.\d+)?/g); // Extract numbers
  return match && match.length === 4 ? parseFloat(match[3]) : 1; // Return opacity (default: 1)
};

const parseStyleString = (styleString, defaultStyles) => {
  const styleObj = { ...defaultStyles };

  styleString.split(";").forEach((style) => {
    const [prop, value] = style.split(":").map((s) => s.trim());
    if (!prop || !value) return;

    switch (prop) {
      case "font-weight":
        styleObj.fontWeight = value;
        break;
      case "font-style":
        styleObj.fontStyle = value;
        break;
      case "text-decoration":
        styleObj.textDecoration = value.includes("underline")
          ? "underline"
          : "none";
        break;
      case "text-align":
        styleObj.textAlign = value;
        break;
      case "vertical-align":
        styleObj.verticalAlign = value;
        break;
      case "font-size":
        styleObj.fontSize = value;
        break;
      case "font-family":
        styleObj.fontFamily = value.replace(/['"]+/g, ""); // Remove quotes
        break;
      case "color":
        styleObj.textColor = rgbToHex(value);
        break;
      case "background-color":
        styleObj.backgroundColor = rgbToHex(value);
        styleObj.backgroundOpacity = getOpacityFromRGBA(value);
        break;
      default:
        break;
    }
  });

  return styleObj;
};

const getDefaultStyles = () => ({
  textColor: "#666666",
  backgroundColor: "#ffffff",
  backgroundOpacity: 1,
  fontWeight: "normal", // Default is normal
  fontStyle: "normal", // No italic
  textDecoration: "none", // No underline
  textAlign: "center", // Centered by default
  verticalAlign: "bottom", // Subtitles appear at the bottom
  fontSize: "16px", // Reasonable default
  fontFamily: "Arial", // FFmpeg default font
});;

export const getSelectedStyle = () => {
  const selection = window.getSelection();
  const defaultStyles = getDefaultStyles();
  if (!selection.rangeCount) return defaultStyles;

  const range = selection.getRangeAt(0);
  let selectedNode = range.startContainer;

  // Move up the DOM tree to find the <span> with data-slate-leaf="true"
  let closestSpan =
    selectedNode.nodeType === Node.TEXT_NODE
      ? selectedNode.parentElement
      : selectedNode;

  while (
    closestSpan &&
    closestSpan.getAttribute("data-slate-leaf") !== "true"
  ) {
    closestSpan = closestSpan.parentElement;
  }

  if (closestSpan) {
    const styleString = closestSpan.getAttribute("style") || "";
    return parseStyleString(styleString, defaultStyles);
  }

  return defaultStyles;
};
