import {
    ADD_START_TIME_OFFSET,
    DISABLE_TRANSCRIPT_EDITOR,
    ENABLE_TRANSCRIPT_EDITOR,
    GET_TRANSCRIPT_FAIL,
    GET_TRANSCRIPT_OK,
    GET_TRANSCRIPT,
    RELOAD_TRANSCRIPT_FAIL,
    RELOAD_TRANSCRIPT_OK,
    RELOAD_TRANSCRIPT,
    SAVE_TRANSCRIPT_FAIL,
    SAVE_TRANSCRIPT_OK, SET_TRANSCRIPT_SPEAKER,
    SAVE_TRANSCRIPT,
    SET_TRANSCRIPT_HIGHLIGHTS,
    SET_TRANSCRIPT_STYLES,
    SET_TRANSCRIPT,
    FINISH_CONVERT_TO_SUBTITLES,
    CONVERT_TO_SUBTITLES,
} from '../sagas/constants'
import Transcript from '../models/Transcript'
import Immutable from 'immutable'
import uuidV4 from 'uuid/v4'
import Speaker from '../models/Speaker'
import TranscriptSegment from '../models/TranscriptSegment'
import TranscriptWord from '../models/TranscriptWord'
import { getHighlightsFromContent } from '../helpers/highlightUtils'
import { getStylesFromContent } from '../helpers/stylesUtils'
import { convertSegmentsToSubtitleSegments } from '../helpers/subtitleUtil'
import {
    areSegmentsEmpty,
    createTranscriptFromJson,
    createEmptyTranscript,
} from '../helpers/transcriptUtils'


const initialState = {
    loading: false,
    loaded: false,
    error: false,

    saving: false,
    saved: true,
    saveError: false,

    isConvertingToSubtitles: false,

    empty: false,
    disabled: false,
    notification: '',

    id: null,
    recordId: null,

    data: new Transcript(),
    transcriptJson: null
};


export default function reducer(state = initialState, action) {
    switch (action.type) {
      case GET_TRANSCRIPT: {
        return { ...state, loading: true, error: false };
      }

      case GET_TRANSCRIPT_FAIL: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true,
        };
      }

      case GET_TRANSCRIPT_OK: {
        const transcriptJson = action.payload;
        const data = createTranscriptFromJson(transcriptJson);

        return {
          ...state,
          loading: false,
          loaded: true,
          error: false,
          empty: areSegmentsEmpty(transcriptJson.segments),

          id: transcriptJson.id,
          recordId: transcriptJson.recordId,

          data,
          transcriptJson,
        };
      }

      // Triggers for savings
      case SET_TRANSCRIPT: {
        const { contentState, transcriptJson } = action;
        let data = null;
        if (contentState) {
          const highlights = getHighlightsFromContent(contentState);
          const styles = getStylesFromContent(contentState);
          const segments = contentState.getBlockMap().map(
            (block) =>
              new TranscriptSegment({
                id: block.data.get("id"),
                words: block.data.get("words"),
                speaker: block.data.get("speaker"),
                newLine: block.data.get("newLine"),
              })
          );

          data = new Transcript({
            startTimeOffset: state.data.startTimeOffset,
            isConvertedToSubtitles: state.data.isConvertedToSubtitles,
            speakers: state.data.speakers,
            segments,
            highlights: new Immutable.List(highlights),
            styles: new Immutable.List(styles),
          });
        } else {
          data = jsonToAmberTranscript(transcriptJson);
        }

        return {
          ...state,
          data,
          transcriptJson,
        };
      }

      case SET_TRANSCRIPT_HIGHLIGHTS: {
        const { contentState, highlights: highlightsJson } = action;
        const highlights =
          highlightsJson || getHighlightsFromContent(contentState);

        const data = new Transcript({
          startTimeOffset: state.data.startTimeOffset,
          isConvertedToSubtitles: state.data.isConvertedToSubtitles,
          speakers: state.data.speakers,
          segments: state.data.segments,
          highlights: new Immutable.List(highlights),
          styles: state.data.styles,
        });

        return {
          ...state,
          data,
        };
      }
      case SET_TRANSCRIPT_STYLES: {      
        const { contentState, styles: stylesJson } = action;
        const styles = stylesJson || getStylesFromContent(contentState);

        const data = new Transcript({
          startTimeOffset: state.data.startTimeOffset,
          isConvertedToSubtitles: state.data.isConvertedToSubtitles,
          speakers: state.data.speakers,
          segments: state.data.segments,
          highlights: state.data.highlights,
          styles: new Immutable.List(styles),
        });

        return {
          ...state,
          data,
        };
      }

      case SET_TRANSCRIPT_SPEAKER: {
        let { speakers } = state.data;
        if (action.id) {
          const index = speakers.findIndex(
            (speaker) => speaker.id === action.id
          );
          speakers = speakers.setIn([index, "name"], action.name);
        } else {
          // add a new speaker
          speakers = speakers.push(
            new Speaker({ id: uuidV4(), name: action.name })
          );
        }
        const data = new Transcript({
          startTimeOffset: state.data.startTimeOffset,
          isConvertedToSubtitles: state.data.isConvertedToSubtitles,
          speakers,
          segments: state.data.segments,
          highlights: state.data.highlights,
          styles: state.data.styles,
        });

        return {
          ...state,
          data,
        };
      }

      case ADD_START_TIME_OFFSET: {
        const { startTimeOffset, editorState, transcriptJson } = action;
        let data;

        if (transcriptJson) {
          data = createTranscriptFromJson({
            ...transcriptJson,
            isConvertedToSubtitles: state.data.isConvertedToSubtitles,
          });
        } else {
          const newValueToAdd = +startTimeOffset - +state.data.startTimeOffset;
          const contentState = editorState.getCurrentContent();

          const highlights = getHighlightsFromContent(contentState).map(
            (highlight) => ({
              start: highlight.start + +newValueToAdd,
              end: highlight.end + +newValueToAdd,
              startPosition: highlight.startPosition,
              endPosition: highlight.endPosition,
            })
          );

          const styles = getStylesFromContent(contentState).map((style) => ({
            start: style.start + +newValueToAdd,
            end: style.end + +newValueToAdd,
            startPosition: style.startPosition,
            endPosition: style.endPosition,
            types: [...style.types],
            color: style.color || null,
            backgroundColor: style.backgroundColor || null,
            fontSize: style.fontSize || null,
          }));

          const segments = contentState.getBlockMap().map(
            (block) =>
              new TranscriptSegment({
                id: block.data.get("id"),
                words: block.data
                  .get("words")
                  .map((word) =>
                    word
                      .update("start", (start) => start + +newValueToAdd)
                      .update("end", (end) => end + +newValueToAdd)
                  ),
                speaker: block.data.get("speaker"),
              })
          );

          data = new Transcript({
            startTimeOffset,
            isConvertedToSubtitles: state.data.isConvertedToSubtitles,
            speakers: state.data.speakers,
            segments,
            highlights: new Immutable.List(highlights),
            styles: state.data.styles,
          });
        }

        return {
          ...state,
          data,
          transcriptJson,
        };
      }

      case FINISH_CONVERT_TO_SUBTITLES: {
        return {
          ...state,
          isConvertingToSubtitles: false,
        };
      }

      case CONVERT_TO_SUBTITLES: {
        const { subtitleLength } = action;
        const data = convertSegmentsToSubtitleSegments(
          state.data,
          subtitleLength
        );
        return {
          ...state,
          isConvertingToSubtitles: true,
          data,
        };
      }

      case SAVE_TRANSCRIPT: {
        return {
          ...state,
          saving: true,
          saved: false,
          saveError: false,
        };
      }

      case SAVE_TRANSCRIPT_OK: {
        return {
          ...state,
          saving: false,
          saved: true,
          saveError: false,
        };
      }

      case SAVE_TRANSCRIPT_FAIL: {
        const { errorCode, errorMessage } = action.payload;
        return {
          ...state,
          saving: false,
          saved: false,
          saveError: true,
          errorCode,
          errorMessage,
        };
      }

      case RELOAD_TRANSCRIPT: {
        return {
          ...state,
          loading: true,
          loaded: false,
          error: false,
        };
      }

      case RELOAD_TRANSCRIPT_FAIL: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true,
        };
      }

      case RELOAD_TRANSCRIPT_OK: {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: false,
        };
      }

      case DISABLE_TRANSCRIPT_EDITOR: {
        return {
          ...state,
          disabled: true,
        };
      }

      case ENABLE_TRANSCRIPT_EDITOR: {
        return {
          ...state,
          disabled: false,
        };
      }

      default: {
        return state;
      }
    }
}

function jsonToAmberTranscript(transcriptJson) {
    return new Transcript({
        startTimeOffset: transcriptJson.startTimeOffset,
        isConvertedToSubtitles: transcriptJson.isConvertedToSubtitles,
        speakers: speakerJsonArrayToList(transcriptJson.speakers),
        segments: segmentsJsonArrayToList(transcriptJson.segments),
        highlights: new Immutable.List(transcriptJson.highlights),
        styles: new Immutable.List(transcriptJson.styles),
    });
}

function speakerJsonArrayToList(speakersJson) {
    return new Immutable.List(
      speakersJson.map(
        ({name, spkid}) => new Speaker(
          {name, id: spkid}
        )
      )
    );
}

function segmentsJsonArrayToList(segmentsJson) {
    return new Immutable.List(
      segmentsJson.map(
        segment => jsonSegmentToAmberSegment(segment)
      )
    );
}

function jsonSegmentToAmberSegment(segment) {
    let segmentData = {}
    for (let key in segment) {
        segmentData[key] = key === 'words' ? wordsJsonArrayToList(segment[key]) : segment[key]
    }
    return new TranscriptSegment(segmentData);
}

function wordsJsonArrayToList(words) {
    return new Immutable.List(
      words.map(
        word => new TranscriptWord(word)
      )
    );
}