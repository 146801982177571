export const pt = {
  locale: "pt",
  messages: {
    MISC: {
      formErrRequired: "Esse campo é mandatório",
      formErrEmail: "O endereço de email precisa ser válido",
      formHintYes: "Sim",
      formHintQuestion: "Use {{ what }}?",
      signedInAs: "Você está usando a conta de",
      logout: "Sair",
      youOwner: "Você",
      closeDialogBtn: "Fechar",
      ariaCloseDialog: "Fechar conversa",
      clearSearch: "Limpar busca",
      serverErrorDefaultToastMsg: "Erro de servidor",
      ienoticeTitle: "Navegador incompatível",
      ienoticeTxt:
        "O Internet Explorer é incompatível com a nossa plataforma. Por favor, use outro navegador como o Google Chrome ou Microsoft Edge",
      ienoticeDismiss: "Entendi",
      requestFullTranscriptBodyDialog:
        "Se você quiser que os nossos especialistas linguísticos aperfeiçoem a sua transcrição, por favor, carregue o arquivo novamente e selecione a opção 'perfect'.",
      requestFullTranscriptTitle: "Obtenha seu texto com 100% de precisão",
      requestFullTranscriptBody:
        "Pedir um orçamento de uma versão aperfeiçoada por um dos nossos transcritores.",
    },
    CONFIRM_SUBMIT: {
      title: "Tem certeza de que deseja enviar o arquivo?",
      checkbox1:
        "Verifiquei as notas adicionais e implementei os requisitos específicos do cliente, se solicitado.",
      checkbox2:
        "Toda a saída ASR do arquivo foi verificada com o verificador ortográfico e gramatical.",
      checkbox3: "A transcrição foi criada de acordo com as diretrizes.",
      back: "Leve-me de volta ao arquivo",
      submit: "Enviar arquivo para revisão",
    },
    TRANSCRIPT_ERROR: {
      message:
        "Você não tem mais acesso a este arquivo. O arquivo pode ter sido reatribuído. Se você tiver dúvidas, fale com a equipe de operações.",
      button: "Voltar ao dashboard",
    },
    NAV: {
      titleAccount: "Conta",
      titleAccountNew: "Adicionar usuário",
      titleAccountMy: "Minha conta",
      titleBilling: "Faturamento",
      titleInvoices: "Faturas",
      titleCheckout: "Checkout",
      titleCoupon: "Usar cupom",
      titleInvite: "Convidar amigos",
      titleLogin: "Entrar",
      titlePasswordReset: "Reiniciar senha",
      titleEmailvalidate: "Validar email",
      titlePlan: "Plano",
      titleCosts: "Custos",
      titleRecords: "Seus Arquivos",
      titleRecordsTooltip: "Voltar para o Painel de Controle",
      titleRegister: "Cadastrar-se",
      titleTransactions: "Transações ",
      titleUploads: "Uploads",
      titleDashboard: "Painel de controle",
      titleManageUsers: "Administrar usuários",
      titleAddUser: "Adicionar usuário",
      titleManageTranscriptions: "Administrar transcrições",
      tooltipAdmin: "Admin",
      titleAdminUser: "Administrar usuário",
      tooltipLanguage: "Mudar idioma",
      newBadge: "novo",
      help: "Ajuda",
      currentPlanFallback: "Comprar Plano Completo",
      currentPlanTooltip: "Plano Atual",
    },
    LANGUAGES: {
      en: "Inglês",
      nl: "Holandês",
      de: "Alemão",
      fr: "Francês",
      es: "Espanhol",
      it: "Italiano",
      pt: "Português",
    },
    ACCOUNT: {
      titleForm: "Detalhes da Conta",
      username: "Nome de usuário",
      email: "Email",
      firstName: "Nome",
      lastName: "Sobrenome",
      company: "Empresa",
      pwd: "Senha",
      newPwd: "Nova senha",
      confirmPwd: "Confirmar senha",
      changePwd: "Mudar senha",
      createPwd: "Criar senha",
      msgUsernameMaxlength:
        "O nome precisa ter menos de {{ quantity }} caracteres.",
      msgPwdLength: "A senha precisa ter pelo menos{{ quantity }} caracteres.",
      msgPwdPattern:
        "A senha precisa conter, pelo menos, um número, uma letra maiúscula, uma letra minúscula e um símbolo.",
      msgPwdConfirm: "A nova senha precisa ser confirmada",
      msgPwdMismatch: "Precisa coincidir com a anterior",
      loading: "Carregando detalhes...",
      update: "Carregando dados",
      updating: "Salvando dados...",
      save: "Salve usuário",
      saving: "Salvando usuário...",
    },
    ADMIN: {
      add: {
        donateSeconds: "Doe segundos",
      },
      dashboard: {
        loading: "Carregando o painel de controle...",
        noResults: "Nenhum elemento no painel de controle disponível",
      },
      transactions: {
        id: "Identidade",
        owner: "Proprietário",
        seconds: "Segundos",
        date: "Data",
        noId: "Nenhuma identidade especificada",
        loading: "Carregando transações...",
        noResults: "Nenhuma transação para mostrar",
        results: "Transações recuperadas:",
      },
      transcriptions: {
        created: "Criada",
        id: "Identidade",
        name: "Nome",
        user: "Usuário",
        status: "Status",
        length: "Duração",
        type: "Tipo",
        loading: "Carregando transcrições...",
        noResults: "Nenhuma transcrição para mostrar",
        results: "Transcrições recuperadas:",
      },
      users: {
        created: "Criado",
        username: "Nome de usuário",
        fullname: "Nome completo",
        status: "status",
        minutes: "Tempo de áudio",
        pause: "Pausar usuário",
        activate: "Ativar usuário",
        delete: "Deletar usuário",
        subscribed: "Cadastrado",
        loading: "Carregando usuários...",
        noResults: "Nenhum usuário para mostrar",
        results: "Usuários recuperados:",
        deleteConfirm: "Tem certeza que deseja deletar '{{ name }}'?",
        deleteOk: "Sim",
        deleteCancel: "Cancelar",
        search: "Procurar usuários",
      },
      user: {
        navBackToUsers: "Todos os usuários",
        navEdit: "Perfil",
        navTranscriptions: "Transcrições",
        navTransactions: "Transações",
        navDonate: "Doe",
        fullName: "Nome completo",
        availableSeconds: "Tempo disponível",
        created: "Criado em",
        isSubscribed: "Assinatura atual",
        isntSubscribed: "Nenhuma assinatura",
        status: "Status",
        userType: "Tipo",
        language: "Idioma favorito",
        makeAdmin: "Recursos de administrador",
        cancelSubscription: "Cancelar assinatura",
        cancellingSubscription: "Cancelando...",
      },
      donate: {
        title: "Doar horas",
        username: "Nome de usuário do destinatário",
        seconds: "Número of segundos",
        secondsFormatted: "Horas : minutos : segundos",
        comment: "Mensagem para o destinatário",
        submit: "Enviar",
        submitting: "Enviando...",
      },
      sendvalidationemail: {
        submit: "Enviar email de autenticação",
        submitting: "Enviando",
      },
    },
    BILLING: {
      titleForm: "Detalhes de Faturamento",
      firstName: "Nome",
      lastName: "Sobrenome",
      email: "Email",
      phone: "Telefone",
      company: "Empresa",
      vatNumber: "Número de IVA",
      address: "Endereço",
      address2: "Endereço 2",
      city: "Cidade",
      country: "País",
      zipCode: "Código postal",
      state: "Estado",
      loading: "Carregando detalhes...",
      save: "Salvar detalhes",
      saving: "Salvando detalhes...",
    },
    CHECKOUT: {
      choose: "1.Escolha um plano",
      setup: "2.Configurar pagamento",
      confirm: "3. Confirmação",
      loadingStripe: "Carregando stripe...",
      titleCard: "Detalhes do Cartão",
      titleBilling: "Detalhes da Fatura",
      titleSummary: "Resumo do pedido",
      subscribe: "Cadastrar-se no plano",
      subscribing: "Cadastrando...",
      info: "mberScript aceita os seguintes cartões de débito e de crédito. Pagamentos são feitos com segurança através do Stripe.",
      labelNameOnCard: "Nome no cartão",
      labelCard: "Cartão de débito ou crédito",
      termsDisclaimer:
        "Caso continue você estará aceitando os termos da Amberscript",
      termsDisclaimerLinkTxt: "Termos de serviço",
    },
    SUMMARY: {
      plan: "Plano:",
      cost: "Custo:",
      beyond: "Além",
      hrs: "hs",
      hour: "hora",
      billingCycle: "Ciclo de faturamento:",
      billingInterval: "mensalmente",
      total: "Total:",
      loading: "Carregando resumo...",
      error: "Erro ao carregar resumo :(",
    },
    COSTS: {
      titlePlan: "Plano atual",
      titleCosts: "Sumário",
      titleInvoices: "Lista de faturas",
      noResultsPlan: "Comprar o plano completo",
      noResultsCosts: "Você não tem nenhum custo para esse mês",
      noResultsInvoices: "Não exitem faturas ainda.",
      loadingSummary: "Carregando resumo...",
      loadingInvoices: "Carregando faturas...",
      summary:
        "Esse mês você usou {{ time }} com um custo total de{{ cost }} euros.",
      remainder:
        "Com o seu plano {{ planName }} você paga {{ price }} por hora",
      remainderB: "Você tem {{ num }} horas grátis  incluídas",
      remainderC1: "e um custo fixo de {{ cost }} por{{ interval }}.",
      remainderC2: "e sem custo fixo.",
      INVOICES: {
        invoiceNr: "Número",
        created: "Criado",
        description: "Descrição",
        monthPeriod: "Mês",
        yearPeriod: "Ano",
        nettoAmount: "Neto",
        vatAmount: "IVA",
      },
    },
    COUPON: {
      title: "Usar um cupom",
      inputLabel: "Código do cupom",
      submit: "Enviar",
      submitting: "Enviando...",
      cancel: "Cancelar",
      invalidCouponCode: "Código do cupom inválido",
    },
    EDITOR: {
      tooManyRows: "Muitas linhas",
      busy: "Carregando ...",
      feedbackBtn: "Dê feedback",
      shortcutsBtn: "Mostre todos os atalhos",
      shortcutsHint: "Quer usar o Play ou Pausar? Use Control + Espaço",
      shortcutHintAltClick:
        "Quer pular o audio quando clicar no texto? Mantenha a tecla alt pressionada.",
      shortcutHintNewLine:
        "Deseja adicionar uma nova linha dentro de um segmento? Clique em shift + enter",
      shortcutsTitle: "Atalhos do teclado",
      ariaShortcutsClose: "Fechar atalhos",
      replayTour: "Refazer o tour",
      errorEmptySegments:
        "Amber não reconheceu nenhuma palavra no idioma selecionado. Por favor confirme se o seu áudio inclui diálogos e se você tem créditos suficientes no seu saldo. Por favor entre em contato caso tenha alguma dúvida!",
      errorLoadAudio: "Erro ao carregar o arquivo",
      errorLoadXML: "Erro ao carregar o arquivo",
      errorReadAudio: "Erro ao ler o arquivo",
      loadingAudio: "Carregando audio...",
      loading: "Carregando transcrição...",
      parsing: "Analisando transcrição...",
      speakerClear: "Deletar orador",
      speakerAddBtn: "Adicionar novo",
      speakerAddTitle: "Adicionar orador",
      speakerEditTitle: "Editar orador",
      findreplTitle: "Buscar e substituir",
      findreplFindLabel: "Buscar:",
      findreplReplLabel: "Substituir com:",
      findreplReplBtn: "Substituir todos",
      infoTitle: "Informação sobre a transcrição",
      infoClose: "Fechar informação sobre a transcrição",
      infoNameLabel: "Nome",
      infoDateLabel: "Data",
      infoCancel: "Cancelar",
      infoSave: "Salvar",
      infoSaving: "Salvando...",
      feedbackTitle: "Avaliação",
      feedbackRatingLabel: "Como estava a qualidade da transcrição?",
      feedbackCommentLabel: "Deixe um comentário",
      feedbackSubmit: "Enviar",
      feedbackSubmitting: "Enviando...",
      feedbackVal1: "Ruim",
      feedbackVal2: "Mais ou menos",
      feedbackVal3: "OK",
      feedbackVal4: "Boa",
      feedbackVal5: "Muito boa",
      exportBtn: "Exportar",
      exportXML: "XML",
      exportWord: "Word",
      autosaveSuccess: "Todas as alterações salvas",
      autosavePending: "Salvando...",
      autoSaveSaved: "Salvo",
      autoSaveError: "Erro ao salvar",
      autoSaveTryAgain: "Clique aqui para tentar novamente",
      metaCreated: "Criado",
      metaLength: "Extensão",
      metaStartTimeOffset: "Horário de início",
      metaStartTimeOffsetLabel: "Horário de início (hh:mm:ss:ms)",
      metaStartTimeOffsetTitle: "Realinhar o início do codigo de tempo",
      metaStartTimeOffsetDescription:
        "Realinhar o início do codigo de tempo para sincronizar com a fonte original. Insira o tempo que deve ser adicionado em todos os timestamps originais da transcrição",
      metaStartTimeOffsetSubmit: "Realinhar o início do codigo de tempo",
      metaWords: "Words",
      metaPlaybackRate: "Velocidade",
      metaPlaybackRateTip: "Mudar a velocidade da reprodução",
      controlsTranslation: "Adicionar traduções",
      addNewLanguage: "Adicionar um novo idioma",
      controlsSpellCheck: "Toggle de verificação ortográfica",
      controlsSpellCheckShortcut: "(ctrl+g)",
      controlsReplace: "Substituir",
      controlsReplaceShortcut: "(ctrl+f)",
      controlsUndo: "Desfazer",
      controlsUndoShortcut: "(ctrl+z)",
      controlsRedo: "Refazer",
      controlsRedoShortcut: "(ctrl+shift+z)",
      controlsHighlight: "Destacar",
      controlsHighlightShortcut: "(ctrl+h)",
      controlsSubtitles: "Legendas",
      controlsSubtitlesShortcut: "Converter para legendas",
      rewindBtn: "Retroceder",
      resumeBtn: "Retomar",
      ariaPlay: "Play",
      ariaPause: "Pausa",
      blockTextLength: "{textLength} caracteres",
      adjustStartTimestampHeader: "Ajustar o início dos timestamps",
      adjustEndTimestampHeader: "Ajustar o final dos timestamps",
      adjustDialogSubmit: "Ajustar",
      adjustDialogCancel: "Cancelar",
      adjustTimestampDescription:
        "Timestamps máximos e mínimos são determinados pelos timestamps das palavras anteriores e posteriores.",
      subtitlesDialogHeader: "Converter para legendas",
      subtitlesDialogDescription:
        "Automaticamente converta sua transcrição em frases prontas para legendas. A Amberscript dividirá o texto em frases menores para caber na tela.",
      subtitlesDialogNote:
        "Atenção: Essa ação não pode ser revertida automaticamente, mas o seu texto continuará sendo editável.",
      subtitlesDialogAdvancedOptions: "Opções avançadas",
      subtitlesDialogSubmit: "Converter",
      subtitlesDialogSubmitLoading: "Por favor, aguarde",
      subtitlesDialogCancel: "Cancelar",
      noEditingForMobileOrTablet:
        "No momento, a opção de edição não está disponível no seu navegador. Amberscript funciona melhor em um laptop ou PC e usando Google Chromem, Microsoft Edge, Apple Safari ou Mozilla Firefox.",
      emailValidationTitle: "Esse email ainda não foi validado",
      emailValidationDescription:
        "Para exportar seu arquivo ou copiar a sua transcrição, por favor valide o seu email.",
      btnAlreadyActivated: "Eu já ativei o meu email",
      btnSendActivationAgain: "Reenviar email de verificação",
      btnActivationSent: "Email de verificação enviado!",
      alertLoggedOutTitle: "Necessário fazer o login",
      alertLoggedOutDescription:
        "Você saiu da sua conta em outra aba. Por favor, entre na sua conta novamente para usar Amberscript.",
      alertLoggedOutButton: "Vá para login",
      alertAccessDeniedTitle: "Accesso negado",
      alertAccessDeniedDesciption:
        "Você não tem acesso para editar esse arquivo.",
      btnApproveOrReject: "Aprove ou rejeite...",
      btnDetails: "Detalhes",
      dialogJobFeedback: {
        btnApprove: "Aprovar",
        btnReject: "Rejeitar",
        title: "Aprovar ou rejeitar a verificação de qualidade",
        rateTranscriber:
          "Avalie {email} em cada área para este trabalho, sendo 1 menor, sendo 5 da mais alta qualidade. Preciso de ajuda? Leia as diretrizes de controle de qualidade.",
        textAreaPlaceholder: "Adicionar notas...",
        notesExplanation:
          "Obrigatório: Adicione notas, mesmo ao aprovar, então o transcritor pode ver seu feedback.",
        transcription: "Tekst",
        syntax: "Richtlijnen",
        grammar: "Spelling/Tikfouten/Grammatica",
        punctuation: "Interpunctie/Syntax",
        research: "Pesquisa (nomes e jargão)",
        difficulty: "Nível de dificuldade",
        difficultyExplanation:
          "Esta pontuação não faz parte do cálculo total de pontuação.",
        total: "Rating de transcrição total",
        tooltip: {
          transcription:
            "It is checked whether the spoken text has been correctly converted into written text. This includes the accurate reproduction of words, sentences and statements without errors or omissions, in accordance with the guidelines. It is checked whether there were any listening errors that could have led to inaccurate or incorrect interpretation of the transcriptions. (30%)",
          syntax:
            "This is where it is checked whether the transcription was carried out in accordance with the established guidelines.This includes the use of time marks for unintelligible passages, the labelling of speakers, the observance of pauses and sentence breaks as well as other guidelines that apply to the transcription. All special guidelines are evaluated especially strictly. It must be checked whether the formatting of the transcript is appropriate. This may include line spacing, font size, arrangement of text elements and other aspects of the visual presentation of the transcript. (25%)",
          grammar:
            "In this category, grammatical errors as well as spelling and typing errors in the transcript are evaluated. The aim is to ensure that the text is both grammatically correct and free of spelling errors according to guidelines. This includes checking for errors such as misspelled words, missing capitalisation and errors in the use of abbreviations. (25%)",
          punctuation:
            "It is checked whether punctuation marks such as full stops and commas have been used correctly to improve the readability and comprehensibility of the text. It also assesses whether the use of quotes is appropriate and whether other punctuation marks have been used correctly. Another aspect of this category is the correct division of long sentences. (15%)",
          research:
            "This category focuses on evaluating the research of technical terms, proper names and other specialised expressions that appear in the transcript. It is checked whether these terms have been spelt and used correctly and, if available, whether terms from a glossary have been used. (5%)",
        },
      },
      SUBTITLE: {
        sliderShorterLabel: "Mais curto",
        sliderLongerLabel: "Mais longo",
        sliderRecommendedLabel: "Recomendado",
        slidercharsPerSecLabel: "{charsPerSec} caracteres / segundo",
      },
      EXPORT: {
        title: "Exportar texto",
        format: "Formato",
        export: "Exportar",
        optionsTitle: "Opções para exportar",
        optionsTimestamps: "Incluir timestamps",
        optionsSpeakers: "Incluir oradores",
        optionsIncludeHighlights: "Incluir destaques",
        optionsHighlights: "Exportar apenas os destaques",
        maxCharsPerRow: "Máximo de caracteres por linha",
        milliseconds: "Milliseconds",
        fps: "FPS",
        cancel: "Cancelar",
        ok: "Exportar",
        transcribedByAmberScript:
          "Essa transcrição de linguagem para texto foi criada por www.amberscript.com",
        wayOfExport: "Como deseja exportar o arquivo?",
        text: "Texto",

        textDesc:
          "Documento em Word ou TXT. Melhor opçāo para transcricode entrevistas, reniões e marcação de mídia.",
        subtitleDesc:
          "SRT, VVT ou EBU-STL. Pode ser usado para mídias sociais, Youtube, reprodutores de vídeo e mais.",
        video: "Vídeo",
        videoDesc:
          "Descarregue vídeo com ou sem legendas. Melhor opção para compartilhar vídeos com legendas fixadas (burned-in) em redes sociais.",
        rules: "Regras",
        burnSubToVideo: "Fixar legendas nos vídeos - Em breve",
        subtitleRule:
          "Máximo {maxChar} linhas, {maxLine} carácteres por linha.",
        realignRecommendation:
          "Recomendamos realinhar as marcas de tempo antes de exportar o arquivo.",
        realignRecommendationDesc:
          "Caso edite as suas transcrições, o áudio precisará ser realinhado para acompanhar as edições.",
        realignRecommendation1: "Rápido ",
        realignRecommendation1Desc:
          "Realinhamento rápido e geral. Pode não sair 100% preciso.",
        realignRecommendation2: "Completo - Em breve",
        realignRecommendation2Desc:
          "Realinhamento completo e preciso do seu áudio. Isso pode levar vários minutos.",
        realignRecommendation3: "Exportar sem realinhar",
        realignRecommendation3Desc:
          "As suas marcas de tempo podem estar imprecisas.",
        emailNotification:
          "Você receberá um email quando o seu arquivo estiver pronto para download.",
        options: "Opções",
        whatIsJson:
          "JSON é um formato de dados que pode ser utilizado para aplicativos.",
        audio: "Audio",
        audioDesc: "Descarregue o seu arquivo de mídia original.",
        downloadAudio: "Descarregue áudio.",
        downloadVideo: "Descarregue vídeo.",
        exportSubtitleTitle: "Exportar Legendas",
        custom: "Customizar",
        maxNrRows: "Número máximo de linhas",
        maxCharPerRow: "Máximo de caracteres por linha, incluindo espaços",
        titleTranscript: "Exportar sua transcrição",
        downloadFile: "Baixar arquivo de áudio",
        asAFile: "Como um arquivo",
        asAFileDesc:
          "SRT, VTT ou EBU-STL. O arquivo pode ser usado para mídias sociais, Youtube, players de vídeo e muito mais.",
        download: "Baixar",
        asAVideoWithBurnIn: "Vídeo com legendas embutidas",
        asAVideoWithBurnInDesc:
          "As legendas aparecerão permanentemente no arquivo do vídeo. Você pode exportar como mp4, etc.",
        createNewVersion: "Criar uma nova versão",
        lastExport: "Ultimo download:",
        additionalOptions: "Outras opções",
        downloadAsText: "Baixar como um texto",
        downloadAudioFile: "Baixar arquivo de áudio",
        downloadVideoFile: "Baixar arquivo de vídeo",
        postVideoOnYoutube: "Publicar vídeo no youtube",
        intelligentExport: "Exportação inteligente",
        summary: "Resumo",
        summaryTooltip: "Gerar um resumo da sua transcrição",
        anonymization: "Anonimização",
        anonymizationTooltip:
          "Anonimize todos os dados pessoais (nomes, moradas, etc.) da sua transcrição.",
        cleanRead: "Leitura limpa",
        cleanReadTooltip:
          "Gera uma transcrição que é editada para facilitar a leitura.",
        hint: "Tenha em atenção que, para esta funcionalidade, os dados deixam a plataforma Amberscript e a UE.",
      },
      BURN_IN: {
        processingVideo: "Processando o vídeo",
        confirmationTitle: "Você está satisfeito com seu arquivo? ",
        confirmationText:
          "Você pode embutir a versão atual destas legendas uma vez só. Se você editar o arquivo de legendas depois e deseja embutir-las novamente, uma cobrança adicional se aplica.",
        continue: "Sim, prosseguir",
        back: "Não, continue editando",
        successTitle: "Pedido com sucesso",
        successText1:
          "Vai levar tempo para processar. Uma vez concluído, você será notificado e poderá baixar o vídeo através da",
        successText2: "do seu dashboard, ou no editor.",
        export: "tela no painel",
        returnToEditor: "Voltar ao editor",
        continueToDashboard: "Continuar ao dashboard",
        notAvailableText: "Deseja criar uma exportação adicional?",
        notAvailableLink: "Contate o suporte ao cliente",
      },
      toast: {
        onCopy: "Copiar, colar e recortar não estão disponíveis.",
        onCut: "Copiar, colar e recortar não estão disponíveis.",
        onPaste: "Copiar, colar e recortar não estão disponíveis.",
      },
      RPT: {
        title: "Deixe-nos editar o texto por você",
        description:
          "Quando você seleciona o serviço de transcrição manual, nossos profissionais ganham acesso ao seu arquivo. As alterações feitas no arquivo serão reiniciadas e seu arquivo será travado até as correções serem finalizadas.",
        description2: "Clique em OK para prosseguir para o checkout",
      },
      BANNER: {
        firstStepTitle: "Verifique e revise sua transcrição",
        firstStepDescDescTranscriber:
          "Você pode pular isto, mas pode haver erros em suas legendas",
        firstStepDesc:
          "Você pode pular isto, mas pode haver erros em suas legendas. Você também pode solicitar uma revisão de um de nossos especialistas em idiomas",
        secondStepTitle:
          "Terminou a edição? Clique em criar legendas para alinhar & formatar as legendas",
        secondStepDesc:
          "Você também poderá salvar a transcrição para seus registros",
      },
      PRE_QC: {
        emptyState: "Obtenha rapidamente feedback sobre o seu documento.",
        runPreQCBtn: "Inicie o assistente de qualidade",
        spellingIssueFound: " problema ortográfico encontrado",
        spellingIssuesFound: " problemas ortográficos encontrados",
        grammarIssueFound: " problema gramaticais encontrado",
        grammarIssuesFound: " problemas gramaticais encontrados",
        cleanreadIssueFound: " erros com 'Clean Read' encontrados",
        cleanreadIssuesFound: " erros com 'Clean Read' encontrados",
        inaudibleIssueFound: " etiqueta inaudível encontrada",
        inaudibleIssuesFound: " etiquetas inaudíveis encontradas",
        noSuggestion: "Nenhuma sugestão",
        loadingSuggestions: "carregando sugestões...",
        noIssueNotice:
          "Não foram encontrados quaisquer problemas. Bom trabalho!",
        formatting: " erro na formatação",
        formattingIssueFound: " problema de formatação",
        formattingIssuesFound: " problema de formatação",
        reportTitle: "Relatar falso positivo",
        reportBtn: "Relatar falso positivo",
        reportComment: "Comentar",
        reportCommentPlaceholder: "Digite aqui seu comentário",
        spellingInfoTooltip:
          "A ortografia de algumas palavras pode estar incorrecta. Considere a possibilidade de as corrigir ou de comunicar falsos positivos clicando no ícone de bandeira.",
        inaudibleInfoTooltip:
          "Esta secção foi marcada como inaudível. Certifique-se de que utiliza esta etiqueta apenas quando o discurso não é claro.",
        grammarInfoTooltip:
          "Foi encontrado um erro gramatical. Considere verificar a utilização de maiúsculas no início da frase.",
        cleanReadInfoTooltip:
          "Foram encontradas algumas violações das directrizes de leitura limpa. Considere a remoção de palavras repetidas, etiquetas não permitidas e palavras de calão.",
        formattingInfoTooltip:
          "Nota: Devido ao número de {totalIssues} problemas, o Assistente de Qualidade precisa de mais tempo. Tem certeza de que deseja continuar?",
      },
    },
    CREATE_SUBTITLES: {
      title: "Ajude o nosso algoritmo a fazer o seu melhor trabalho",
      title1:
        "Usamos o aprendizado por máquina para alinhar as legendas ao áudio em um formato legível",
      title2:
        "Esta automatização significa que você gasta menos tempo consertando o alinhamento e a formatação das legendas",
      title3:
        "A correção de erros em sua transcrição antes de criar legendas os torna ainda mais precisos",
      note1:
        "Todos os arquivos de legendas em outros idiomas além de inglês, alemão e holandês, com mais de 10 minutos de duração, devem ser submetidos primeiro ao alinhamento das Operações!",
      note2:
        "Uma vez convertido este arquivo em legendas, sua transcrição não estará mais disponível. Por favor exporta a transcrição agora se você precisar dela mais tarde",
      note3:
        "O arquivo desaparecerá temporariamente da sua lista de projetos, e aparecerá novamente quando a conversão for completada",
      note4:
        "Levará alguns minutos para o nosso algoritmo formatar suas legendas",
      note5: "Avisaremos você quando estiverem prontos",
      confirmation1: "Terminei de revisar minha transcrição",
      rule1:
        "Máximo {maxLinesPerSegment} linha, {maxCharsPerLine} caracteres por linha, e {maxDisplayTimePerLine} segundos de tempo de tela",
      rule2: "Número máximo de linhas",
      rule3: "Máximo de caracteres por linha (CPL)",
      rule5: "Tempo máximo de exibição por linha",
      customRules: "Regras personalizadas",
      customRulesDescription:
        "CPL, CPS, Duração mínima/máxima, Linhas por legenda, Intervalo de fotogramas, Contagem",
      includeSpacesInCount: "Incluir espaços na contagem de caracteres",
      includeSpecialCharactersInCount:
        "Incluir caracteres especiais (#,&,%...) na contagem de caracteres",
      maxCharsPerSecond: "Máximo de caracteres por segundo",
      minSubtitleDuration: "Duração mínima da legenda",
      maxSubtitleDuration: "Duração máxima da legenda",
      minFrameGap: "Intervalo mínimo de fotogramas",
      netflixRules: "Regras da Netflix",
      msgSuccess1:
        "O processo começou com sucesso, você será redirecionado de volta para a plataforma",
      msgSuccess2:
        "O processo começou com sucesso, você será redirecionado de volta para o painel de controle",
      error1: "Erro desconhecido ocorrido, por favor tente novamente",
      button1: "Criar legendas",
    },
    OriginalText: {
      title: "Mostrar original",
      transcript: "Transcrição",
    },
    SPELLCHECK: {
      title: "Como acrescentar a verificação ortográfica",
      clickHere: "Clique aqui",
      linkDesc:
        "para receber as instruções completas de como ativar a verificação ortográfica no Chrome",
      step1: "Vá para as configurações do Google Chrome",
      step2: "Selecione as opções avançadas",
      step3: "Clique em idiomas",
      step4: "Abra esse link em uma nova aba do Google Chrome",
      step5: "Na seção de idiomas, adicione o idioma desejado",
      step6: "Reinicie o Google Chrome para aplicar as configurações",
    },
    LOGIN: {
      title: "Bem-vindo de volta",
      subtitle: "Entrar na sua conta",
      alreadyQuestion: "Novo aqui?",
      alreadyAction: "Entrar agora.",
      btn: "Login",
      emailOrUsername: "Email ou nome de usuário",
      loginFormDescription:
        "Você está desconectado ou não tem autorização para ver essa página. Por favor faça o login com a conta correta.",
      errorLogin: "Combinação inválida de email e senha",
      forgotPassword: "Esqueceu a senha?",
      loggedinActionBtn: "Ir para as minhas reproduções",
    },
    PASSWORDRESET: {
      alreadyQuestion: "Precisa de ajuda?",
      alreadyAction: "Entre em contato",
      actionLogin: "Entrar",
      actionRegister: "Cadastrar-se",
      actionOr: "ou",
      loggedinActionBtn: "Ir para as minhas reproduções",
    },
    PASSWORDRECOVER: {
      title: "Esqueceu a senha?",
      subtitle: "Crie ume nova senha aqui.",
      btn: "Recriar minha senha",
      hintUsername:
        "Esse é o nome de usuário que você criou para usar sua conta da Amberscript.",
      or: "ou",
      hintEmail:
        "Esse é o email que você criou para usar sua conta da Amberscript.",
    },
    PASSWORDCHANGE: {
      title: "Mudar sua senha",
      subtitle: "Criar uma nova senha para entrar de novo na Amberscript",
      btn: "Confirmar minha senha",
    },
    EMAILVALIDATE: {
      loading: "Validação em progresso...",
      inviteTitle: "Obrigado por cadastrar-se.",
      inviteSubtitle: "Um email para validar a sua conta foi enviado para",
      successTitle: "Pronto!",
      successSubtitle:
        "Obrigada por validar o seu email. A sua conta está ativa.",
      errorTitle: "Esse link de validação de email está incorreto.",
      errorSubtitle: "Se o problema persistir, nos contate em",
      loggedinActionBtn: "Ir para as minhas reproduções",
    },
    PLAN: {
      hour: "hora",
      select: "Selecionar",
      unsubscribeBtn: "Cancelar",
      unsubscribeMsg:
        "Tem certeza de que deseja cancelar o seu plano {{ plan }}?",
      unsubscribeOk: "Prosseguir",
      unsubscribeCancel: "Cancel",
      yourCurrent: "Seu plano atual",
      vatDisclaimer: "Preços excl. IVA",
      loading: "Carregando planos...",
      noResults: "Nenhum plano disponível no momento",
      showPlans: "Mostrar planos",
    },
    RECORDS: {
      upload: "Carregar arquivo",
      name: "Nome",
      words: "Palavras",
      length: "Duração",
      owner: "Proprietário",
      lastModified: "última alteração",
      created: "Criado",
      more: "Mais",
      ariaMore: "Abrir menu de ações",
      share: "Compartilhar",
      delete: "Deletar",
      deleting: "Deletando...",
      deletedSuccess: "{{ recordId }} deletado com sucesso.",
      slicedFileMsg:
        "Esse arquivo de audio/video foi dividido devido a quantidade insuficiente de créditos restantes.",
      invalid: "Arquivo inválido",
      errorGeneric: "Essa transcrição parece estar com erros.",
      loading: "Carragando seus arquivos...",
      noResults: "Nenhum arquivo criado ainda",
      tipUploading: "Carregando...",
      tipOpen: "Agendado para transcrição",
      tipBusy: "Transcrevendo...",
      tipDone: "Pronto",
      tipError: "Erro",
      scheduled:
        "{{ audioName }} está agendado para transcrição. Isso pode levar alguns minutos.",
      transcribing: "Transcrevendo {{ audioName }}, {{ progress }}...",
      transcribed: "Transcrição de {{ audioName }} está pronta.",
      uploadErrorServer:
        "Erro de servidor. Falha ao carregar o arquivo {{ audioName }}.",
      uploadError: "Carregamento do {{ audioName }} falhou.",
      uploadProgress: "Carregando  {{ audioName }}:",
      uploadStart: "Começar carregamento do {{ audioName }}.",
      uploaded: "Carregamento do{{ audioName }} foi completado.",
      uploadhelpTitle: "Carregando para Amberscript",
      uploadhelpTxt: "Amberscript aceita os seguintes formatos de audio/video:",
      uploadUnsupprtedFormatTitle: "Formato não compatível",
      uploadUnsupprtedFormatMsg:
        "Você precisa selecionar um arquivo de audio ou video. Formatos aceitos:",
      uploadUnsupprtedFormatBtn: "Entendi",
      uploadConfirmTitle: "Pronto para transcrição!",
      uploadConfirmMsgTop:
        "Nota: Confirme se a qualidade do seu audio/video é boa o suficiente. Deduzimos que o seu arquivo:",
      uploadconfirmList1: "Contém vozes claramente distinguíveis",
      uploadconfirmList2: "Sem ruído ao fundo",
      uploadconfirmList3: "Não contém sotaques fortes",
      uploadConfirmMsgBottom:
        "Se algum desses critérios não for atendido,a Amberscript não pode garantir a qualidade das transcrições.",
      uploadconfirmSelectASR: "Selecionar idioma",
      uploadConfirmOk: "Continuar",
      uploadConfirmCancel: "Cancelar",
      unsupportedUploadTitle: "Carregamento não compatível",
      unsupportedUploadMsg:
        "Desculpe, mas o seu navegador não é compatível com carregamento de arquivos de audio/video.",
      unsupportedUploadBtn: "Entendi",
      deleteMsg: "Tem certeza de que deseja deletar '{{ name }}'?",
      deleteOk: "Confirmar",
      deleteCancel: "Cancelar",
      noCreditTitle: "Upgrade do seu plano",
      noCreditMsg:
        "Você não tem créditos suficientes. Para continuar a carregar arquivos de audio/video assine um plan.",
    },
    REGISTER: {
      title: "Comece o seu teste grátis",
      subtitle:
        "Receba os seus 30 minutos de transcrição de áudio or video grátis",
      alreadyQuestion: "Tem uma conta?",
      alreadyAction: "Entrar",
      btn: "Cadastre-se",
      forgotPassword: "Esqueceu a senha?",
      createPwd: "Escolha uma senha",
      confirmPwd: "Confirmar senha",
      loggedinActionBtn: "Ir para as minhas reproduções",
    },
    SHORTCUTS: {
      playPause: "Comece e pause o seu arquivo de audio",
      rewind: "Retroceda o seu audio em 5 segundos",
      forward: "Adiante seu audio em 5 segundos",
      skip: "Pule uma palavra",
      reverse: "Reverta uma palavra",
      highlight: "Destacar texto",
      undo: "Desfazer",
      redo: "Refazer",
      altClick: "Pular audio quando clicar",
    },
    STATUS: {
      actionBtn: "Comprar o plano completo",
      remainingMsg: "Saldo das transcrições: {{ balance }}",
    },
    UPLOADS: {
      id: "Identidade",
      owner: "Proprietário",
      length: "Duração",
      date: "Data",
      noId: "Nenhuma identidade especificada",
      loading: "Carregando arquivos...",
      noResults: "Nenhum arquivo carregando ainda",
    },
    TOUR: {
      back: "Voltar",
      close: "Fechar",
      last: "Obrigado!",
      next: "Próximo",
      skip: "Pular",
      dismissBtn: "Não mostrar isso novamente",
      EDITOR: {
        beginTitle: "Bem-vindo!",
        begin: "Nesse tour nós mostramos como aproveitar melhor a Amberscript",
        altClick:
          "Avance o seu audio pressionando a tecla alt enquanto clica em uma palavra específica.",
        play: "Com o play você inicia o arquivo de áudio. Você pode facilmente interromper e continar com o atalho comando + espaço",
        rewind:
          "Quando você utiliza esse botão, você automaticamente volta o arquivo em 5 segundos. O atalho para isso e control + r.",
        highlight:
          "Com esse botão você pode facilmente adicionar destaques na linha do tempo do seu áudio. Selecione uma parte do texto na transcrição e note o destaque na sua linha do tempo",
        shortcuts:
          "Esse botão mostra vários outros atalhos que você pode utilizar para trabalhar com a Amberscript ainda mais rápido. ",
        startTime:
          "Você pode ajustar o começo do codigo de tempo em cada parágrafo para sincronizar com a fonte original, por exemplo um vídeo, clicando aqui.",
        end: "É isso! Mais perguntas? Entre em contato <a href='mailto:info@amberscript.com'>info@amberscript.com</a>",
      },
    },
    REFERRAL: {
      title: "Ajude seus amigos a economizar tempo",
      description:
        "Convide seus amigos a usarem Amberscript -para cada pessoa inscrita, você pode ganhar até {{signupCreditMinutes}} créditos em minutos.",
      terms: "Termos",
      enterEmail: "Adicionar email",
      invitationSent:
        "Seu convite foi enviado. Fique à vontade para convidar mais amigos!",
      sendButton: "Envie convites",
      shareInviteLink: "Compartilhe o seu link",
      transcriptionCredit: "Seu crédito de transcrição",
      pending: "Pendente",
      earned: "Recebido",
      copy: "Copiar",
      copied: "Copiado!",
      errorTitle: "O código de indicação expirou",
      errorDescription:
        "Vamos avisar sobre novas campanhas por email ou mídias sociais.",
    },
    REALIGN: {
      btnName: "Realinhar a marcação de tempo",
      dialogTitle: "Alinhamento em progresso",
      dialogContent: "Atualizando a marcação de tempo, por favor aguarde...",
    },
    TRANSACTIONS: {
      id: "Identidade",
      owner: "Proprietário",
      seconds: "Segundos",
      date: "Data",
      noId: "Nenhuma identidade especificada",
      loading: "Carregando transações...",
      noResults: "Nenhuma transação para mostrar",
    },
    THANKS: {
      mainMsg: "Obrigado!",
      summaryTitle: "Resumo do pedido",
    },
    SCRAMBLED_DIALOG: {
      title: "Oops! Ocorreu um erro em seu documento",
      description1:
        "O texto dentro de seu documento foi misturado acidentalmente.",
      description2:
        "Como resultado, seu documento tem que ser restaurado a um estado anterior. Sua última edição será perdida, pois a página precisa ser renovada.",
      titleFeedback:
        "Por favor, informe-nos que ação tomou antes de ver este diálogo para que possamos remediar a questão.",
      descriptionButton: "A página será atualizada automaticamente",
    },
    COUNTRIES: {
      AFG: "Afghanistan",
      ALA: "Ã…land Islands",
      ALB: "Albania",
      DZA: "Algeria",
      ASM: "American Samoa",
      AND: "Andorra",
      AGO: "Angola",
      AIA: "Anguilla",
      ATA: "Antarctica",
      ATG: "Antigua and Barbuda",
      ARG: "Argentina",
      ARM: "Armenia",
      ABW: "Aruba",
      AUS: "Australia",
      AUT: "Austria",
      AZE: "Azerbaijan",
      BHS: "Bahamas",
      BHR: "Bahrain",
      BGD: "Bangladesh",
      BRB: "Barbados",
      BLR: "Belarus",
      BEL: "Belgium",
      BLZ: "Belize",
      BEN: "Benin",
      BMU: "Bermuda",
      BTN: "Bhutan",
      BOL: "Bolivia, Plurinational State of",
      BES: "Bonaire, Sint Eustatius and Saba",
      BIH: "Bosnia and Herzegovina",
      BWA: "Botswana",
      BVT: "Bouvet Island",
      BRA: "Brazil",
      IOT: "British Indian Ocean Territory",
      BRN: "Brunei Darussalam",
      BGR: "Bulgaria",
      BFA: "Burkina Faso",
      BDI: "Burundi",
      KHM: "Cambodia",
      CMR: "Cameroon",
      CAN: "Canada",
      CPV: "Cape Verde",
      CYM: "Cayman Islands",
      CAF: "Central African Republic",
      TCD: "Chad",
      CHL: "Chile",
      CHN: "China",
      CXR: "Christmas Island",
      CCK: "Cocos (Keeling) Islands",
      COL: "Colombia",
      COM: "Comoros",
      COG: "Congo",
      COD: "Congo, the Democratic Republic of the",
      COK: "Cook Islands",
      CRI: "Costa Rica",
      CIV: "CÃ´te d'Ivoire",
      HRV: "Croatia",
      CUB: "Cuba",
      CUW: "CuraÃ§ao",
      CYP: "Cyprus",
      CZE: "Czech Republic",
      DNK: "Denmark",
      DJI: "Djibouti",
      DMA: "Dominica",
      DOM: "Dominican Republic",
      ECU: "Ecuador",
      EGY: "Egypt",
      SLV: "El Salvador",
      GNQ: "Equatorial Guinea",
      ERI: "Eritrea",
      EST: "Estonia",
      ETH: "Ethiopia",
      FLK: "Falkland Islands (Malvinas)",
      FRO: "Faroe Islands",
      FJI: "Fiji",
      FIN: "Finland",
      FRA: "France",
      GUF: "French Guiana",
      PYF: "French Polynesia",
      ATF: "French Southern Territories",
      GAB: "Gabon",
      GMB: "Gambia",
      GEO: "Georgia",
      DEU: "Germany",
      GHA: "Ghana",
      GIB: "Gibraltar",
      GRC: "Greece",
      GRL: "Greenland",
      GRD: "Grenada",
      GLP: "Guadeloupe",
      GUM: "Guam",
      GTM: "Guatemala",
      GGY: "Guernsey",
      GIN: "Guinea",
      GNB: "Guinea-Bissau",
      GUY: "Guyana",
      HTI: "Haiti",
      HMD: "Heard Island and McDonald Islands",
      VAT: "Holy See (Vatican City State)",
      HND: "Honduras",
      HKG: "Hong Kong",
      HUN: "Hungary",
      ISL: "Iceland",
      IND: "India",
      IDN: "Indonesia",
      IRN: "Iran, Islamic Republic of",
      IRQ: "Iraq",
      IRL: "Ireland",
      IMN: "Isle of Man",
      ISR: "Israel",
      ITA: "Italy",
      JAM: "Jamaica",
      JPN: "Japan",
      JEY: "Jersey",
      JOR: "Jordan",
      KAZ: "Kazakhstan",
      KEN: "Kenya",
      KIR: "Kiribati",
      PRK: "Korea, Democratic People's Republic of",
      KOR: "Korea, Republic of",
      KWT: "Kuwait",
      KGZ: "Kyrgyzstan",
      LAO: "Lao People's Democratic Republic",
      LVA: "Latvia",
      LBN: "Lebanon",
      LSO: "Lesotho",
      LBR: "Liberia",
      LBY: "Libya",
      LIE: "Liechtenstein",
      LTU: "Lithuania",
      LUX: "Luxembourg",
      MAC: "Macao",
      MKD: "Macedonia, the former Yugoslav Republic of",
      MDG: "Madagascar",
      MWI: "Malawi",
      MYS: "Malaysia",
      MDV: "Maldives",
      MLI: "Mali",
      MLT: "Malta",
      MHL: "Marshall Islands",
      MTQ: "Martinique",
      MRT: "Mauritania",
      MUS: "Mauritius",
      MYT: "Mayotte",
      MEX: "Mexico",
      FSM: "Micronesia, Federated States of",
      MDA: "Moldova, Republic of",
      MCO: "Monaco",
      MNG: "Mongolia",
      MNE: "Montenegro",
      MSR: "Montserrat",
      MAR: "Morocco",
      MOZ: "Mozambique",
      MMR: "Myanmar",
      NAM: "Namibia",
      NRU: "Nauru",
      NPL: "Nepal",
      NLD: "Netherlands",
      NCL: "New Caledonia",
      NZL: "New Zealand",
      NIC: "Nicaragua",
      NER: "Niger",
      NGA: "Nigeria",
      NIU: "Niue",
      NFK: "Norfolk Island",
      MNP: "Northern Mariana Islands",
      NOR: "Norway",
      OMN: "Oman",
      PAK: "Pakistan",
      PLW: "Palau",
      PSE: "Palestinian Territory, Occupied",
      PAN: "Panama",
      PNG: "Papua New Guinea",
      PRY: "Paraguay",
      PER: "Peru",
      PHL: "Philippines",
      PCN: "Pitcairn",
      POL: "Poland",
      PRT: "Portugal",
      PRI: "Puerto Rico",
      QAT: "Qatar",
      REU: "RÃ©union",
      ROU: "Romania",
      RUS: "Russian Federation",
      RWA: "Rwanda",
      BLM: "Saint BarthÃ©lemy",
      SHN: "Saint Helena, Ascension and Tristan da Cunha",
      KNA: "Saint Kitts and Nevis",
      LCA: "Saint Lucia",
      MAF: "Saint Martin (French part)",
      SPM: "Saint Pierre and Miquelon",
      VCT: "Saint Vincent and the Grenadines",
      WSM: "Samoa",
      SMR: "San Marino",
      STP: "Sao Tome and Principe",
      SAU: "Saudi Arabia",
      SEN: "Senegal",
      SRB: "Serbia",
      SYC: "Seychelles",
      SLE: "Sierra Leone",
      SGP: "Singapore",
      SXM: "Sint Maarten (Dutch part)",
      SVK: "Slovakia",
      SVN: "Slovenia",
      SLB: "Solomon Islands",
      SOM: "Somalia",
      ZAF: "South Africa",
      SGS: "South Georgia and the South Sandwich Islands",
      SSD: "South Sudan",
      ESP: "Spain",
      LKA: "Sri Lanka",
      SDN: "Sudan",
      SUR: "Suriname",
      SJM: "Svalbard and Jan Mayen",
      SWZ: "Swaziland",
      SWE: "Sweden",
      CHE: "Switzerland",
      SYR: "Syrian Arab Republic",
      TWN: "Taiwan, Province of China",
      TJK: "Tajikistan",
      TZA: "Tanzania, United Republic of",
      THA: "Thailand",
      TLS: "Timor-Leste",
      TGO: "Togo",
      TKL: "Tokelau",
      TON: "Tonga",
      TTO: "Trinidad and Tobago",
      TUN: "Tunisia",
      TUR: "Turkey",
      TKM: "Turkmenistan",
      TCA: "Turks and Caicos Islands",
      TUV: "Tuvalu",
      UGA: "Uganda",
      UKR: "Ukraine",
      ARE: "United Arab Emirates",
      GBR: "United Kingdom",
      USA: "United States",
      UMI: "United States Minor Outlying Islands",
      URY: "Uruguay",
      UZB: "Uzbekistan",
      VUT: "Vanuatu",
      VEN: "Venezuela, Bolivarian Republic of",
      VNM: "Viet Nam",
      VGB: "Virgin Islands, British",
      VIR: "Virgin Islands, U.S.",
      WLF: "Wallis and Futuna",
      ESH: "Western Sahara",
      YEM: "Yemen",
      ZMB: "Zambia",
      ZWE: "Zimbabwe",
    },
    GLOSSARY: {
      title: "Dicionário",
      names: "Nomes",
      terms: "Palavras",
      notFound:
        "Não foi possível encontrar o dicionário. Da próxima vez, crie um dicionário antes de carregar o seu ficheiro para melhorar a precisão do seu texto.",
    },
    MACHINE_TRANSLATIONS: {
      direct: "Automática",
      perfect: "Profissional",
    },
    EDITOR_LANGUAGE: {
      en: "Inglês",
      "en-us": "Inglês (Estados Unidos)",
      "en-uk": "Inglês (Reino Unido)",
      "ar-sa": "Árabe (Arábia Saudita)",
      ar: "Árabe",
      nl: "Holandês",
      de: "Alemão",
      fr: "Francês",
      es: "Espanhol",
      it: "Italiano",
      pt: "Português",
      da: "Dinamarquês",
      fi: "Finlandês",
      no: "Norueguês (Bokmål)",
      sv: "Sueco",
      pl: "Polonês",
      ro: "Romeno",
      uk: "Ucraniano",
      ru: "Russo",
      bg: "Búlgaro",
      cs: "Checo",
      el: "Grego",
      et: "Estónio",
      hu: "Húngaro",
      ja: "Japonês",
      ko: "Coreano",
      lt: "Lituano",
      lv: "letão",
      sk: "Eslovaco",
      sl: "Esloveno",
      tr: "Turco",
      zh: "Chinês",
    },
  },
};
