import uuidV4 from 'uuid/v4'
import Immutable from 'immutable'
import Transcript from '../models/Transcript'
import TranscriptSegment from '../models/TranscriptSegment'
import Highlight from '../models/Highlight'
import Speaker from '../models/Speaker'
import TextStyle from '../models/TextStyle'
import TranscriptWord from '../models/TranscriptWord'

const DEFAULT_SPEAKER_ID = 'S0';

/**
 * Are transcript segments empty
 *
 * Deal wit empty transcripts, `segments` from the API can be:
 * 1) `[]` when there is an error on transcription server
 * 2) `[null]`
 * 3) `[{speaker: "Amber", words: []}]` when I select all text and delete
 *
 * @param  {Array}  segments
 * @return {Boolean}
 */
export function areSegmentsEmpty(segments) {
    const count = segments.length;

    if (!count) return true;
    if (count === 1) {
        if (!segments[0]) return true;
        if (segments[0].words && !segments[0].words.length) return true;
    }
    return false;
}

/**
 * Create empty transcript
 *
 * @return {Transcript}
 */
export function createEmptyTranscript() {
    const segments = new Immutable.List([createEmptySegment()]);
    const speakers = new Immutable.List([createEmptySpeaker()]);
    const styles = new Immutable.List([]);
    return new Transcript({
        speakers,
        segments,
        styles,
    });
}

// Check if we need to replace the <br> tag with an empty string or not
// 
function shouldReplaceBreakLineTag(indexSegment, indexWord) {
    if (indexSegment === 0) {
        return false;
    } else {
        if (indexWord === 0) {
            return true;
        } else {
            return false;
        }
    }
}


/**
 * Create transcript from json
 *
 * @param  {json} json Transcript
 * @return {Transcript}
 */
export function createTranscriptFromJson(json) {
    const isEmpty = areSegmentsEmpty(json.segments);
    let data;
    let highlights;
    let styles;

    if (isEmpty) {
        data = createEmptyTranscript();
    } else {
        if (json.highlights) {
            highlights = new Immutable.List(
                json.highlights.map(highlight => new Highlight(highlight))
            );
        } else {
            highlights = new Immutable.List([]);
        }
        if (json.styles) {
            styles = new Immutable.List(
                json.styles.map(style => new TextStyle(style))
            );
        } else {
            styles = new Immutable.List([]);
        }
        const speakers = new Immutable.List(
            json.speakers.map(speaker => new Speaker({
                id: speaker.spkid || speaker.name,
                name: speaker.name
            }))
        );
        let startPosition = 0;
        const segments = new Immutable.List(
            json.segments.reduce(function (_segments, segment, indexSegment) {
                if (!segment) {
                    return _segments;
                }
                const { speaker, words } = segment;

                if (words && words.length > 0) {
                    const segment = new TranscriptSegment({
                        id: uuidV4(),
                        speaker,
                        newLine: indexSegment !== 0 && words[0].text.includes("<br>"),
                        words: new Immutable.List(
                            words.reduce((result, { start, end, pristine, conf, text }, indexWord) => {
                                const cleanedText = shouldReplaceBreakLineTag(indexSegment, indexWord) ? text.replace("<br>", "").trim() : text;
                                if (text.length > 0 && text !== ' ') {
                                    // After we added pristine property
                                    if (pristine === true || pristine === false) {
                                        result.push(new TranscriptWord({
                                            id: uuidV4(),
                                            text: cleanedText,
                                            start,
                                            end,
                                            pristine,
                                            startPosition,
                                            endPosition: startPosition + cleanedText.length,
                                            conf,
                                        }));
                                    }
                                    // Old transcription without pristine property
                                    else {
                                        result.push(new TranscriptWord({
                                            id: uuidV4(),
                                            text: cleanedText,
                                            start,
                                            end,
                                            startPosition,
                                            endPosition: startPosition + cleanedText.length,
                                            conf,
                                        }));
                                    }
                                    startPosition += cleanedText.length + 1;
                                }
                                return result;
                            }, [])
                        ),
                    });

                    _segments.push(segment);
                }
                return _segments;
            }, [])
        );

        return new Transcript({
            startTimeOffset: json.startTimeOffset,
            isConvertedToSubtitles: json.isConvertedToSubtitles,
            highlights,
            speakers,
            segments,
            styles
        });
    }
}

/**
 * Create empty speaker
 *
 * @return {Speaker}
 */
function createEmptySpeaker() {
    return new Speaker({
        id: DEFAULT_SPEAKER_ID,
        name: DEFAULT_SPEAKER_ID,
    })
}

/**
 * Create empty segment
 *
 * @return {TranscriptSegment}
 */
function createEmptySegment() {
    return new TranscriptSegment({
        id: uuidV4(),
        speaker: DEFAULT_SPEAKER_ID,
        words: new Immutable.List([
            new TranscriptWord({
                start: 0,
                end: 0.1,
                conf: 0,
                text: ' ',
                id: uuidV4(),
            })
        ]),
        newLine: false,
    })
}

/**
 * Get words from the transcript 
 * @param {*} transcript 
 */
export const getTranscriptWords = (transcript) => {
    const words = [];
    transcript.segments.forEach(s => {
        if (s.words)
            s.words.forEach(w => words.push(w));
    });
    return words
}

export function processStyles(styles) {
  // Step 1: Sort styles by startPosition
  styles.sort((a, b) => a.startPosition - b.startPosition);

  let processedStyles = [];

  for (let i = 0; i < styles.length; i++) {
    let current = styles[i];

    for (let j = 0; j < processedStyles.length; j++) {
      let existing = processedStyles[j];

      // If they don't overlap, continue
      if (
        existing.endPosition <= current.startPosition ||
        existing.startPosition >= current.endPosition
      ) {
        continue;
      }

      // Overlapping case: Split existing style
      if (
        existing.startPosition < current.startPosition &&
        existing.endPosition > current.endPosition
      ) {
        // Case: Current style is inside an existing one -> Split into three parts
        let beforeSplit = { ...existing, endPosition: current.startPosition };
        let afterSplit = { ...existing, startPosition: current.endPosition };

        processedStyles.splice(j, 1); // Remove the old overlapping style
        processedStyles.push(beforeSplit, current, afterSplit);
        current = null; // No need to add `current` again
        break;
      } else if (existing.startPosition < current.startPosition) {
        // Case: Existing style partially overlaps before current -> Trim existing
        existing.endPosition = current.startPosition;
      } else if (existing.endPosition > current.endPosition) {
        // Case: Existing style partially overlaps after current -> Trim existing
        existing.startPosition = current.endPosition;
      }
    }

    if (current) {
      processedStyles.push(current);
    }
  }

  return processedStyles.filter(
    (style) => style.startPosition < style.endPosition
  );
}
 
  
  